import React from 'react'
import './aboutus.css';
import { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";

//images
import solutionsPic from "../../images/Solutions_Cover_pic.png";
import ourBusinessPic from "../../images/Our_Business_Pic.png";
import customerApp from "../../images/keyFeaturesDigitalApp.jpg";
import warehouseLoading from "../../images/keyFeaturesWarehouseLoading.jpg";
import warehouseRack from "../../images/keyFeaturesWarehouseRack.jpg";
import customerService from "../../images/keyFeaturesCustomerService.jpg";

const AboutUs = () =>{

	const [smallStyle, setSmallStyle] = useState(false);

	useEffect(()=>{

		window.addEventListener('resize', (event)=>{
			let screenWidth = document.documentElement.clientWidth;

			if (screenWidth <= 920)
			{
				setSmallStyle(true)
			}
			else
			{
				setSmallStyle(false);
			}
		});

	}, [smallStyle])

	
	//On initial window load
	useEffect(()=>{
		
		let screenWidth = document.documentElement.clientWidth;

		if (screenWidth <= 920)
		{
			setSmallStyle(true)
		}
		else
		{
			setSmallStyle(false);
		}

	}, [smallStyle])

	return(
		<div className={!smallStyle ? "AboutUs" : "AboutUsSmall"}>
			<Helmet>
				<title>Pasarnow - About Us</title>
				<meta name="description" content="Food supply chain platform helping various B2B customers source fresh & staple products directly from farmers and principals." />
				<meta name="keywords" content="PDD, Pasarnow, Sayuran, Buah, Fruits, Vegetables, Fresh Produce, Online Market, Distributor" />
			</Helmet>
			<div className={!smallStyle ? "SolutionSection" : "SolutionSectionSmall"}>
				<div className={!smallStyle ? "SolutionSectionHalf" : "SolutionSectionSplit"}>
					<h1>Solutions That Impact Every Aspect of Business</h1>
					<label>We are here to simplify & digitize the food supply chain & distribution business, propelling value creations for all stakeholders across Indonesia.</label>
				</div>
				<div className={!smallStyle ? "SolutionSectionHalf" : "SolutionSectionSplit"}>
					<img src={solutionsPic}/>
				</div>
			</div>
			<div className={!smallStyle ? "BusinessModelSection" : "BusinessModelSectionSmall"}>
				<h1>KNOW OUR BUSINESS</h1>
				<img src={ourBusinessPic}/>
				<p>Pasarnow is a food supply chain and distribution company that helps better connect farmers and brand principals with various 
				business customers. We provide end-to-end solutions for our customers that include convenience of ordering, wide 
				assortment of SKUs, consistent quality & supply of products, flexible delivery times & wide coverage.</p>
				<p style={{marginBottom: 50+'px'}}>We have also become the preferred enabler of brand principals for the horeca, instituion & online channels.</p>
			</div>
			<div className="KeyFeaturesSection">
				<div className={!smallStyle ? "KeyFeaturesSectionHeader" : "KeyFeaturesSectionHeaderSmall"}> 
					<h1>Key Features</h1> <label id={!smallStyle ? "KeyFeaturesHeaderArrow" : "KeyFeaturesHeaderArrowSmall"}>→</label>
				</div>
				<div className="KeyFeaturesSectionBody">
					<div className={!smallStyle ? "KeyFeaturesBodyBlock" : "KeyFeaturesBodyBlockSmall"}>
						<img src={customerApp}/>
						<div className="KeyFeaturesBodyTextBlock">
							<h2>Sourcing</h2>
							<ol>
								<li>Direct access to supply</li>
								<li>Wide variety of SKU</li>
								<li>Data-driven</li>
							</ol>
						</div>
					</div>
					<div className={!smallStyle ? "KeyFeaturesBodyBlock KeyFeaturesBlockNotFirst" : "KeyFeaturesBodyBlockSmall"}>
						<img src={warehouseRack}/>
						<div className="KeyFeaturesBodyTextBlock">
							<h2>Fullfilment</h2>
							<ol>
								<li>Next day delivery</li>
								<li>In Class Food Safety & QC</li>
								<li>Over 99% availability rate</li>
							</ol>
						</div>
					</div>
					<div className={!smallStyle ? "KeyFeaturesBodyBlock KeyFeaturesBlockNotFirst" : "KeyFeaturesBodyBlockSmall"}>
						<img src={warehouseLoading}/>
						<div className="KeyFeaturesBodyTextBlock">
							<h2>Logistics</h2>
							<ol>
								<li>Vast network of micro-hubs</li>
								<li>Flexible delivery times</li>
								<li>Cold chain supported</li>
							</ol>
						</div>
					</div>
					<div className={!smallStyle ? "KeyFeaturesBodyBlock KeyFeaturesBlockNotFirst" : "KeyFeaturesBodyBlockSmall"}>
						<img src={customerService}/>
						<div className="KeyFeaturesBodyTextBlock">
							<h2>After Sales</h2>
							<ol>
								<li>Dedicated in-house Customer Service</li>
								<li>Sameday resolution</li>
								<li>Over 97% Customer Satisfaction</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AboutUs;