import React from 'react';
import './distributorship.css';
import { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";

//images
import warehouseLaptop from "../../images/warehouse_laptop.jpg";
import eyesOnAgility from "../../images/eyes_on_agility.jpg";
import trustwortyAndReliable from "../../images/Trustworthy_And_Reliable.jpg";
import qualityFocused from "../../images/Quality_Focused.jpg";

//Logos
import kkv from "../../images/DistributionFootprint/kkv.png";
import klikmart from "../../images/DistributionFootprint/KlikMart.png";
import jumpStart from "../../images/DistributionFootprint/JumpStart.png";
import timeZone from "../../images/DistributionFootprint/Timezone.png";
import vendkiosk from "../../images/DistributionFootprint/Vendkiosk.png";
import elmart from "../../images/DistributionFootprint/Elmart.png";
import funworld from "../../images/DistributionFootprint/funworld.png";
import sociolla from "../../images/DistributionFootprint/Sociolla.png";

import streetGrocer from "../../images/DistributionFootprint/StreetGrocer.png";
import papiMart from "../../images/DistributionFootprint/Papimart.jpeg";
import niceso from "../../images/DistributionFootprint/logo_niceso.jpg";
import monsterMart from "../../images/DistributionFootprint/MonsterMart.jpeg";
import tropikana from "../../images/DistributionFootprint/Tropikana.png";
import funcity from "../../images/DistributionFootprint/funcity.png";
import zone2000 from "../../images/DistributionFootprint/zone2000.png";
import linkShop from "../../images/DistributionFootprint/Linkshop.png";
import goto from "../../images/DistributionFootprint/goto.png";

import grab from "../../images/DistributionFootprint/grab.png"; 
import astro from "../../images/DistributionFootprint/Astro.png";
import garudaShop from "../../images/DistributionFootprint/GarudaShop.png";  
import indofoodCBP from "../../images/DistributionFootprint/IndofoodCBP.png"; 
import pasarnow from "../../images/DistributionFootprint/pasarnow_logo.png"; 
import brand_logo from "../../images/DistributionFootprint/brand_logo.png"; 
import omron from "../../images/DistributionFootprint/Omron.png"; 
import gajatunggal from "../../images/DistributionFootprint/GajaTunggal.png"; 

import kalbe from "../../images/DistributionFootprint/kalbe.png";
import gogobli from "../../images/DistributionFootprint/Gogobli.png";
import bakrie from "../../images/DistributionFootprint/Universitas_Bakrie.png";
import trisakti from "../../images/DistributionFootprint/Universitas_Trisakti.png";
import tnt from "../../images/DistributionFootprint/TnTBeautyShop.png";
import ranch from "../../images/DistributionFootprint/Ranch-Market-logo.png";
import grandLucky from "../../images/DistributionFootprint/GrandLucky.png";
import garuda from "../../images/DistributionFootprint/GarudaIndonesia.png";

import makeupBeauty from "../../images/DistributionFootprint/MakeupBeauty.png";
import lulu from "../../images/DistributionFootprint/LuLu-Logo-ENG.png";
import foodhall from "../../images/DistributionFootprint/FoodHall.png";
import indomaret from "../../images/DistributionFootprint/Logo_Indomaret.png";
import hero from "../../images/DistributionFootprint/Hero_supermarket.png";
import medplus from "../../images/DistributionFootprint/Medplus.png";
import halodoc from "../../images/DistributionFootprint/halodoc.png";
import youtap from "../../images/DistributionFootprint/youtap.png";

import bukalapak from "../../images/DistributionFootprint/bukalapak.png";
import payakumbuah from "../../images/DistributionFootprint/Payakumbuah.png";
import pizzahut from "../../images/DistributionFootprint/pizzahut.png";
import texas from "../../images/DistributionFootprint/Texas_Chicken_logo.png";
import union from "../../images/DistributionFootprint/union.png";
import ismaya from "../../images/DistributionFootprint/ismaya.png";
import jiwa from "../../images/DistributionFootprint/jiwa.png";
import miegacoan from "../../images/DistributionFootprint/mieGacoan.png";
import hangry from "../../images/DistributionFootprint/hangry.png";

import pagiSore from "../../images/DistributionFootprint/pagiSore.png";
import boga from "../../images/DistributionFootprint/Boga.png";
import swissBelhotel from "../../images/DistributionFootprint/Swiss-Belhotel.png";
import mecure from "../../images/DistributionFootprint/Mecure.png";
import aston from "../../images/DistributionFootprint/Aston.png";
import holidayInn from "../../images/DistributionFootprint/HolidayInn.png";
import arena from "../../images/DistributionFootprint/arena.png";
import kopiKenangan from "../../images/DistributionFootprint/kopikenangan.png";

const Distributorship = ()=>{
	const [smallStyle, setSmallStyle] = useState(false);

	useEffect(()=>{

		window.addEventListener('resize', (event)=>{
			let screenWidth = document.documentElement.clientWidth;

			if (screenWidth <= 920)
			{
				setSmallStyle(true)
			}
			else
			{
				setSmallStyle(false);
			}
		});

	}, [smallStyle])

	
	//On initial window load
	useEffect(()=>{
		
		let screenWidth = document.documentElement.clientWidth;

		if (screenWidth <= 920)
		{
			setSmallStyle(true)
		}
		else
		{
			setSmallStyle(false);
		}

	}, [smallStyle])

	return(
		<div className={!smallStyle ? "Distributorship" : "DistributorshipSmall"}>

			<Helmet>
				<title>Pasarnow - Distributorship</title>
				<meta name="description" content="Food supply chain platform helping various B2B customers source fresh & staple products directly from farmers and principals." />
				<meta name="keywords" content="PDD, Pasarnow, Sayuran, Buah, Fruits, Vegetables, Fresh Produce, Online Market, Distributor" />
			</Helmet>

			<img className="warehouseLaptopImage" src={warehouseLaptop}/>

			<div className={!smallStyle ? "PDDInfo" : "PDDInfoSmall"}>
				<h1>Pasarnow Distribusi Digital</h1>
				<h2>Our vision is to become Indonesia's leading distribution company, offering nationwide coverage, 
				top-notch service, and the unwavering trust and loyalty of our Brand Partners.</h2>

				<div className={!smallStyle ? "PDDInfoBlocks" : "PDDInfoBlocksSmall"}>
					<div className={!smallStyle ? "PDDInfoBlock PDDInfoMarginRight" : "PDDInfoBlockSmall"}>
						<img src={eyesOnAgility}/>
						<label>Eyes on <br/>Agility</label>
					</div>

					<div className={!smallStyle ? "PDDInfoBlock PDDInfoMarginRight" : "PDDInfoBlockSmall"}>
						<img src={trustwortyAndReliable}/>
						{!smallStyle ? <label>Trustworthy & Reliable</label> : <label>Trustworthy<br/> & Reliable</label>}
					</div>

					<div className={!smallStyle ? "PDDInfoBlock" : "PDDInfoBlockSmall"}>
						<img src={qualityFocused}/>
						<label>Quality <br/>Focused</label>
					</div>
				</div>
			</div>

			<div className="KeyBenefitsBlock">

				<div className="KeyBenefitsHeader">
					<div className={!smallStyle ? "KeyBenefitsHeaderH1" : "KeyBenefitsHeaderH1Small"}>
						Key Benefits
					</div>
					<div className={!smallStyle ? "KeyBenefitsHeaderH3" : "KeyBenefitsHeaderH3Small"}>
						Power Your Business by Partnering with Us!
					</div>
				</div>

				{/*Benefits List */}

				<div className="BenefitsListBlockTop">
					<div className={!smallStyle ? "BenefitsListBlockColumn" : "BenefitsListBlockColumnSmall"}>
						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								1
							</div>
							<div className="BenefitsListTitle">
								One-Stop Solution
							</div>
							<div className="BenefitsListContent">
							For all your distribution needs: warehousing, sales, delivery, payments, collection & reporting.
							</div>
						</div>

						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								2
							</div>
							<div className="BenefitsListTitle">
								Wide & Extensive Coverage
							</div>
							<div className="BenefitsListContent">
							Our wide-reaching network and dependable infrastructure make sure your customers in Jabodetabek get their products right when they need them.
							</div>
						</div>

						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								3
							</div>
							<div className="BenefitsListTitle">
								Flexible Solutions
							</div>
							<div className="BenefitsListContent">
							We offer customized solutions to meet your unique distribution needs, from warehousing to transportation.
							</div>
						</div>
					</div>

					<div className={!smallStyle ? "BenefitsListBlockColumn" : "BenefitsListBlockColumnSmall"}>
						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								4
							</div>
							<div className="BenefitsListTitle">
								Experienced Team
							</div>
							<div className="BenefitsListContent">
							Our team of experts has decades worth of experience in the industry and is dedicated to ensuring your satisfaction.
							</div>
						</div>

						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								5
							</div>
							<div className="BenefitsListTitle">
								Advanced Technology
							</div>
							<div className="BenefitsListContent">
							Our state-of-the-art systems ensures accurate and timely delivery of your products with real-time tracking.
							</div>
						</div>

						<div className="BenefitsListBlock">
							<div className="BenefitsListNumber">
								6
							</div>
							<div className="BenefitsListTitle">
								Cost-Effective Pricing
							</div>
							<div className="BenefitsListContent">
							We offer competitive pricing without compromising on quality, ensuring optimal value for your company.
							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="OurMarketFootprintTop">
				<h1>Explore Our Market Footprints</h1>

				<div className={!smallStyle ? "MarketFootPrintRow" : "MarketFootPrintRowSmall"}>
					<img src={kkv} className="MarketFootprintLogoMarginRight"/>
					<img src={klikmart} className="MarketFootprintLogoMarginRight" id={!smallStyle ? "klikmartLogo" : ""}/>
					<img src={jumpStart} className="MarketFootprintLogoMarginRight"/>
					<img src={timeZone} className="MarketFootprintLogoMarginRight"/>
					<img src={vendkiosk} className="MarketFootprintLogoMarginRight" id={!smallStyle ? "vendkiosk" : ""}/>
					<img src={elmart} className="MarketFootprintLogoMarginRight"/>
					<img src={funworld} className="MarketFootprintLogoMarginRight"/>
					<img src={sociolla} className="MarketFootprintLogoMarginRight" id={!smallStyle ? "sociolla" : ""}/>
				</div>

				<div className={!smallStyle ? "MarketFootPrintRow" : "MarketFootPrintRowSmall"}>
					<img src={streetGrocer} className="MarketFootprintLogoMarginRight"/>
					<img src={papiMart} className="MarketFootprintLogoMarginRight"/>
					<img src={niceso} className="MarketFootprintLogoMarginRight" id={!smallStyle ? "nicesoLogo" : ""}/>
					<img src={monsterMart} className="MarketFootprintLogoMarginRight"/>
					<img src={tropikana} className="MarketFootprintLogoMarginRight" id={!smallStyle ? "tropikana" : ""}/>
					<img src={funcity} className="MarketFootprintLogoMarginRight"/>
					<img src={zone2000} className="MarketFootprintLogoMarginRight"/>
					<img src={linkShop} className="MarketFootprintLogoMarginRight"/>
					
				</div>

				<div className={!smallStyle ? "MarketFootPrintRow" : "MarketFootPrintRowSmall"}>
					<img src={grab} className="MarketFootprintLogoMarginRight" id="grab"/>
					<img src={astro} className="MarketFootprintLogoMarginRight" id="astro"/>
					<img src={garudaShop} className="MarketFootprintLogoMarginRight" id="nicesoLogo"/>
					<img src={indofoodCBP} className="MarketFootprintLogoMarginRight" id="indofoodCBP"/>
					<img src={pasarnow} className="MarketFootprintLogoMarginRight" id="pasarnowLogo"/>
					<img src={brand_logo} className="MarketFootprintLogoMarginRight" id="nicesoLogo"/>
					<img src={omron} className="MarketFootprintLogoMarginRight" id="omron"/>
					<img src={gajatunggal} className="MarketFootprintLogoMarginRight" id="gajatunggal"/>
				</div>

				<div className={!smallStyle ? "MarketFootPrintRow" : "MarketFootPrintRowSmall"}>
					<img src={kalbe} className="MarketFootprintLogoMarginRight" id="grab"/>
					<img src={gogobli} className="MarketFootprintLogoMarginRight" id="astro"/>
					<img src={bakrie} className="MarketFootprintLogoMarginRight" id="bakrie"/>
					<img src={trisakti} className="MarketFootprintLogoMarginRight" id="trisakti"/>
					<img src={tnt} className="MarketFootprintLogoMarginRight" id="pasarnowLogo"/>
					<img src={ranch} className="MarketFootprintLogoMarginRight" id="ranch"/>
					<img src={grandLucky} className="MarketFootprintLogoMarginRight" id="omron"/>
					<img src={garuda} className="MarketFootprintLogoMarginRight" id="garuda"/>
				</div>

				<div className={!smallStyle ? "MarketFootPrintRow" : "MarketFootPrintRowSmall"}>
					<img src={makeupBeauty} className="MarketFootprintLogoMarginRight" id="pasarnow"/>
					<img src={lulu} className="MarketFootprintLogoMarginRight" id="astro"/>
					<img src={foodhall} className="MarketFootprintLogoMarginRight" id="bakrie"/>
					<img src={indomaret} className="MarketFootprintLogoMarginRight"/>
					<img src={hero} className="MarketFootprintLogoMarginRight"/>
					<img src={medplus} className="MarketFootprintLogoMarginRight" id="ranch"/>
					<img src={halodoc} className="MarketFootprintLogoMarginRight" id="omron"/>
					<img src={youtap} className="MarketFootprintLogoMarginRight" id="garuda"/>
				</div>

				<div className={!smallStyle ? "MarketFootPrintRow" : "MarketFootPrintRowSmall"}>
					<img src={bukalapak} className="MarketFootprintLogoMarginRight" id="bukalapak"/>
					<img src={payakumbuah} className="MarketFootprintLogoMarginRight" id="astro"/>
					<img src={pizzahut} className="MarketFootprintLogoMarginRight" id="bakrie"/>
					<img src={texas} className="MarketFootprintLogoMarginRight"/>
					<img src={union} className="MarketFootprintLogoMarginRight"/>
					<img src={ismaya} className="MarketFootprintLogoMarginRight" id="ranch"/>
					<img src={jiwa} className="MarketFootprintLogoMarginRight" id="omron"/>
					<img src={hangry} className="MarketFootprintLogoMarginRight" id="garuda"/>
				</div>

				<div className={!smallStyle ? "MarketFootPrintRow" : "MarketFootPrintRowSmall"}>
					<img src={pagiSore} className="MarketFootprintLogoMarginRight" id="bukalapak"/>
					<img src={boga} className="MarketFootprintLogoMarginRight" id="boga"/>
					<img src={swissBelhotel} className="MarketFootprintLogoMarginRight" id="swissBelhotel"/>
					<img src={mecure} className="MarketFootprintLogoMarginRight"/>
					<img src={aston} className="MarketFootprintLogoMarginRight"/>
					<img src={holidayInn} className="MarketFootprintLogoMarginRight" id="ranch"/>
					<img src={arena} className="MarketFootprintLogoMarginRight" id="omron"/>
					<img src={kopiKenangan} className="MarketFootprintLogoMarginRight" id="garuda"/>
				</div>
			</div>
		</div>
	)
}

export default Distributorship;