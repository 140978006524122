import React from 'react'
import './home.css';
import { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";


//images
import kalbe from "../../images/home_partner_logo/kalbe_logo.png";
import sinarmas from "../../images/home_partner_logo/Sinarmas-Logo.png";
import takeda from "../../images/home_partner_logo/Logo_Takeda.png";
import manohara from "../../images/home_partner_logo/Manohara-logo.png";
import hero from "../../images/home_partner_logo/Hero_logo.png";
import grab from "../../images/home_partner_logo/Logo-Grabmart.png";
import lulu from "../../images/home_partner_logo/LuLu-Logo.png";
import indomart from "../../images/home_partner_logo/Logo_Indomaret.png";
import indogrosir from "../../images/home_partner_logo/igr_logo_large.png";
import pddLogo from "../../images/PDD_Logo.png";
import marketFootprintText from "../../images/MarketFootprintText.png";


const Home = () =>{
	const [smallStyle, setSmallStyle] = useState(false);


	useEffect(()=>{

		window.addEventListener('resize', (event)=>{
			let screenWidth = document.documentElement.clientWidth;

			if (screenWidth <= 920)
			{
				setSmallStyle(true)
			}
			else
			{
				setSmallStyle(false);
			}
		});

	}, [smallStyle])

	
	//On initial window load
	useEffect(()=>{
		
		let screenWidth = document.documentElement.clientWidth;

		if (screenWidth <= 920)
		{
			setSmallStyle(true)
		}
		else
		{
			setSmallStyle(false);
		}

	}, [smallStyle])

	return(
		<div className={!smallStyle ? "home" : "homeSmall"}>
			<Helmet>
				<title>Pasarnow - Home</title>
				<meta name="description" content="Food supply chain platform helping various B2B customers source fresh & staple products directly from farmers and principals." />
				<meta name="keywords" content="PDD, Pasarnow, Sayuran, Buah, Fruits, Vegetables, Fresh Produce, Online Market, Distributor" />
			</Helmet>

			<div className="GirlSection">
				<div className="GirlSectionText">
					<div className="GirlSectionContainer">
						<h1>Food Supply Chain</h1>
						<h1>Made Simple!</h1>
					</div>
				</div>
			</div>

			
			<div className="GrowingPartners">
				<div className="GrowingPartnersTextContainer">
					<label className={smallStyle ? "GrowingPartnersTextContainerSmall" : ""}>Join Our Growing Partner Network & Unlock<br/>a World of Oppurtunities and Collaborative Success.</label>
				</div>

				<div className="GrowingPartnersImageSection">
					<div className="GrowingPartnersImageLine">
						<img src={kalbe} id="kalbeLogo"/>
						<img src={sinarmas} id="sinarmasLogo"/>
						<img src={takeda} id="takedaLogo"/>
					</div>

					<div className="GrowingPartnersImageLine">
						<img src={manohara} id="manoharaLogo"/>
						<img src={hero} id={!smallStyle ? "heroLogo" : "heroLogoSmall"}/>
						<img src={grab} id={!smallStyle ? "grabLogo" : "grabLogoSmall"}/>
						<img src={lulu} id="luluLogo"/>
					</div>

					<div className="GrowingPartnersImageLine">
						<img src={indomart} id="indomartLogo"/>
						<img src={indogrosir} id="indogrosirLogo"/>
					</div>
				</div>
			</div>
			

			
			<div className={!smallStyle ? "ScaleUp" : "ScaleUpSmall"}>
				<div className={!smallStyle ? "ScaleUpHalf" : "ScaleUpSplit"}>
					<div className={!smallStyle ? "ScaleUpInner" : "ScaleUpInnerSmall"}>
						<h1>Scaling-up your Distribution with Us!</h1>
						<h2>Our distribution company facilitates seamless connections between brand principals and diverse business customers.</h2>
					</div>
				</div>
				<div className={!smallStyle ? "ScaleUpHalf" : "ScaleUpSplit"}>
					<img src={pddLogo}/>
				</div>
			</div>
			

			
			<div className={!smallStyle ? "MarketFootPrintBig" : "MarketFootPrintSmall"}>

				<div className={!smallStyle ? "MarketFootprintHalf" : "MarketFootprintSplit"}>
					<img src={marketFootprintText} id={!smallStyle ? "marketFootprintTextImg" : "marketFootprintTextImgSmall"}/>
				</div>
				<div className={!smallStyle ? "MarketFootprintHalf" : "MarketFootprintSplit2"}>
					<div className="MarketFootprintHalfAgain">
						<div className={!smallStyle ? "MarketFootprintHalfInner" : "MarketFootprintSplitInner"}>
							<h1>20.000+</h1>
							<label>General Trade Outlets</label>
						</div>
						<div className={!smallStyle ? "MarketFootprintHalfInner" : "MarketFootprintSplitInner"} id="MarketFootprintSplitInnerLine2">
							<h1>5.000+</h1>
							<label>Horeca Outlets</label>
						</div>
					</div>
					<div className="MarketFootprintHalfAgain">
						<div className={!smallStyle ? "MarketFootprintHalfInner" : "MarketFootprintSplitInner"}>
							<h1>250+</h1>
							<label>Modern Trade Outlets</label>
						</div>
						<div className={!smallStyle ? "MarketFootprintHalfInner" : "MarketFootprintSplitInner"} id="MarketFootprintSplitInnerLine2">
							<h1>50+</h1>
							<label>Digital Trade Platforms</label>
						</div>
					</div>
				</div>
			</div>
			

		</div>
	)
}

export default Home;