import React from 'react'
import './navBar.css';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router";

//images
import logo from "../../images/pasarnow_logo.png";
import menuIcon from "../../images/BurgerMenu.svg";
import closeIcon from "../../images/closex.svg";


const NavBar = () =>{

	const [dropdown, setDropdown] = useState(false);
	const [useDropDownMenu, setUseDropDownMenu] = useState(false);
  	const navigate = useNavigate();



  	const ShowHideDropDown = () =>{
		let show = !dropdown;

		console.log(`Showing Drop Down: ${show}`);

		setDropdown(show);
	}

	const GoToPage = (url) =>{
    	navigate(url);
    	setDropdown(false);
    	window.scrollTo(0,0);
  	}

  	useEffect(()=>{
		//console.log(`Dropdown state : ${dropdown}`)

		if (dropdown)
		{
		  document.addEventListener('mousedown', (event)=>{
		    //console.log(event.srcElement.id);
		    if (event.srcElement.id !== "NavDropDown")
		    {
		      setDropdown(false)
		    }
		  });
		}

	}, [dropdown]);

	useEffect(()=>{

		window.addEventListener('resize', (event)=>{
			let screenWidth = document.documentElement.clientWidth;

			//console.log(screenWidth);

			if (screenWidth <= 920)
			{
				setUseDropDownMenu(true)
			}
			else
			{
				setUseDropDownMenu(false);
			}
		});

	}, [useDropDownMenu])

	
	//On initial window load
	useEffect(()=>{
		
		let screenWidth = document.documentElement.clientWidth;

		//console.log(screenWidth);

		if (screenWidth <= 920)
		{
			setUseDropDownMenu(true)
		}
		else
		{
			setUseDropDownMenu(false);
		}

	}, [useDropDownMenu])

  	const NavDropDown = ()=>{
		return (
		  <div className="NavbarDropDown">
		    <img className="menuIcon" src={dropdown ? closeIcon : menuIcon} onClick={() => {
		      setDropdown((prev) => !prev)
		    }}/>
		    <nav className={`HideDropDownNav ${dropdown ? "DropDownNav" : ""}`} id="NavDropDown">
		      <ul>
		        <li onClick={()=>{GoToPage("/")}} id="NavDropDown"><label id="NavDropDown">Home</label></li>
		        <li onClick={()=>{GoToPage("/About")}} id="NavDropDown"><label id="NavDropDown">About</label></li>
		        <li onClick={()=>{GoToPage("/Distributorship")}} id="NavDropDown"><label id="NavDropDown">Distributorship</label></li>
		        {/*<li onClick={()=>{GoToPage("/Products")}} id="NavDropDown"><label id="NavDropDown">Products</label></li>*/}
		        <li onClick={()=>{GoToPage("/Contact")}} id="NavDropDown"><label id="NavDropDown">Contact</label></li>
		      </ul>
		    </nav>

		 	<img src={logo} className="DropDownPasarnowLogo" onClick={()=>{GoToPage("/")}}/>
		  </div>
		)
	}

	return(
		<div>
		{ !useDropDownMenu ?
			<div className="navbar">
				<nav className="navcontrols">
					<ul>
						<li onClick={()=>{GoToPage("/")}}><img src={logo} className="logo"/></li>
						<li onClick={()=>{GoToPage("/About")}}>About Us</li>
						<li onClick={()=>{GoToPage("/Distributorship")}}>Distributorship</li>
						{/*<li onClick={()=>{GoToPage("/Products")}}>Products</li>*/}
						<li onClick={()=>{GoToPage("/Contact")}}>Contact</li>
					</ul>
				</nav>
			</div>	
			:
			<NavDropDown/>
		}
		</div>	
		
	)
}


export default NavBar;